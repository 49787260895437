// const API_HOST = "https://devapi.pochta.uz";
const API_HOST =
  process.env.WING_ENV === "uzpost"
    ? "https://prodapi.pochta.uz"
    : process.env.WING_ENV === "postdev"
    ? "https://devapi.pochta.uz"
    : "https://qaapi.pochta.uz";

const SHOP_API_HOST =
  process.env.WING_ENV === "live"
    ? "https://api-shop.wing.ae"
    : "https://api-shop-prelive.wing.ae";

export const HOST = process.env.WING_API || API_HOST;

export const SHOP_HOST = process.env.SHOP_API || SHOP_API_HOST;

export const API_ROOT_URL = `${HOST}/api/v1`;
export const API_V2_ROOT_URL = `${HOST}/api/v2`;
export const SHOP_API_ROOT_URL = `${SHOP_HOST}/api/v1`;

export const MONGO_API_HOST =
  process.env.WING_ENV === "uzpost"
    ? "https://sorting.pochta.uz"
    : process.env.WING_ENV === "postdev"
    ? "https://sorting-dev.pochta.uz"
    : "https://sorting-qa.pochta.uz";

export const MONGO_SOCKET_HOST =
  process.env.WING_ENV === "uzpost"
    ? "wss://sorting.pochta.uz/"
    : "wss://dev-sorting.pochta.uz/";

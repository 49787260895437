import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import MultiLineCell from "./MultiLineCell";
import {
  formatDate,
  TIME_FORMAT,
  DATE_SHORT_FORMAT,
} from "../../helpers/FormatUtils";

const enhancer = compose();

DateTimeCell.propTypes = {
  date: PropTypes.node,
  style: PropTypes.object,
};

function DateTimeCell(props) {
  return (
    <MultiLineCell
      style={props.style}
      firstLine={formatDate(props.date, DATE_SHORT_FORMAT)}
      secondLine={formatDate(props.date, TIME_FORMAT)}
    />
  );
}

export default enhancer(DateTimeCell);

import React, { useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, getFormValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { parsePhone } from "../../helpers/FormatUtils";
import { validateString } from "../../helpers/ValidateUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import FormSelectField from "../form/FormSelectField";
import { formatLanguage, Languages } from "../../constants/LocaleTypes";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import DynamicListColumnsForm from "../order-list-core/DynamicListColumnsForm";
import { updateUser } from "../../api/shared/ProfileApi";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import Cookies from "js-cookie";
import { showSuccessMessage } from "../../reducers/NotificationsReducer";
import { fetchUser } from "../../actions/ProfileActions";
import { fetchUserAccount } from "../../actions/UserAccountActions";
import { fetchMarketplace } from "../../actions/MarketplaceActions";
import fp from "lodash/fp";
import { ORDER_LIST_URL } from "../../constants/CustomerPathConstants";
import { fetchCustomer } from "../../actions/CustomerProfileActions";

const getValues = getFormValues("ProfileForm");

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRTL: getIsRTL(state),
    }),
    {
      showSuccessMessage,
      fetchUser,
      fetchUserAccount,
      fetchMarketplace,
      fetchCustomer,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  reduxForm({
    form: "ProfileForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      firstName: validateString(
        values.firstName,
        props.getLocalisationMessage(
          "please_enter_your_first_name",
          "Please enter your first name",
        ),
      ),
      lastName: validateString(
        values.lastName,
        props.getLocalisationMessage(
          "please_enter_your_last_name",
          "Please enter your last name",
        ),
      ),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
  formValues("smsStatus", "pushStatus", "orderListColumns"),
);

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  smsStatus: PropTypes.bool,
  pushStatus: PropTypes.bool,
  orderListColumns: PropTypes.array,
  disableCustomerName: PropTypes.bool,
  customer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchUserAccount: PropTypes.func,
  fetchMarketplace: PropTypes.func,
  fetchCustomer: PropTypes.func,
  setLocation: PropTypes.func,
  values: PropTypes.object,
};

ProfileForm.defaultProps = {
  disableCustomerName: false,
  customer: false,
};

function ProfileForm({
  disableCustomerName,
  getLocalisationMessage,
  handleSubmit,
  pushStatus,
  smsStatus,
  submitting,
  customer,
  orderListColumns,
  values,
  ...props
}) {
  const [openDynamicColumns, setOpenDynamicColumns] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true} direction="column">
        {customer && openDynamicColumns && (
          <DynamicListColumnsForm
            isLoading={isLoading}
            open={openDynamicColumns}
            onDismiss={() => setOpenDynamicColumns(false)}
            onSubmit={columns => {
              setIsLoading(true);
              updateUser(
                toSnakeCase({
                  ...values,
                  orderListColumns: Object.keys(columns).filter(
                    key => columns[key],
                  ),
                }),
              )
                .catch(ResponseError.throw)
                .then(res => {
                  setIsLoading(false);
                  setOpenDynamicColumns(false);
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_updated",
                      "Successfully Updated",
                    ),
                  );
                  if ("values" in res) {
                    Cookies.set("language", res.values.languageKey);
                  }
                  props.setLocation(ORDER_LIST_URL);
                  props.fetchUser();
                  props.fetchCustomer();
                  props.fetchUserAccount();
                  props.fetchMarketplace();
                })
                .catch(() => setIsLoading(false));
            }}
            initialValues={{
              columns: orderListColumns.reduce((obj, key) => {
                // eslint-disable-next-line no-param-reassign
                obj[key] = true;
                return obj;
              }, {}),
            }}
          />
        )}
        <Card>
          <PageLoading isLoading={submitting} />
          <CardHeader
            title={getLocalisationMessage("details", "Details")}
            style={{ paddingBottom: 0 }}
          />
          <form onSubmit={handleSubmit}>
            <CardContent>
              <FlexBox direction="column" gutter={16}>
                <FlexBox>
                  <FormTextField
                    name="firstName"
                    fullWidth={true}
                    readOnly={disableCustomerName}
                    label={getLocalisationMessage("first_name", "First Name")}
                  />
                </FlexBox>
                <FlexBox>
                  <FormTextField
                    name="lastName"
                    fullWidth={true}
                    label={getLocalisationMessage("last_name", "Last Name")}
                    readOnly={disableCustomerName}
                  />
                </FlexBox>
                <FlexBox>
                  <FormTextField
                    name="email"
                    readOnly={true}
                    fullWidth={true}
                    label={getLocalisationMessage("email", "Email")}
                  />
                </FlexBox>
                <FlexBox>
                  <FormTextField
                    fullWidth={true}
                    name="phone"
                    disabled={customer}
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                    parse={parsePhone}
                  />
                </FlexBox>
                {!customer && (
                  <FlexBox>
                    <FormSelectField
                      name="languageKey"
                      options={Languages}
                      formatOption={value =>
                        formatLanguage(value, getLocalisationMessage)
                      }
                      label={getLocalisationMessage("language", "Language")}
                      fullWidth={true}
                    />
                  </FlexBox>
                )}
                {!customer && (
                  <FlexBox>
                    <FormCheckbox
                      name="isUsingScale"
                      label={getLocalisationMessage("using_scale")}
                    />
                  </FlexBox>
                )}
                {!customer && (
                  <FlexBox>
                    <FormCheckbox
                      name="smsStatus"
                      label={getLocalisationMessage(
                        "receive_sms_when_order_status_changes",
                        "Receive SMS when order status changes",
                      )}
                      disabled={Boolean(smsStatus && !pushStatus)}
                    />
                  </FlexBox>
                )}
                {!customer && (
                  <FlexBox>
                    <FormCheckbox
                      name="pushStatus"
                      label={getLocalisationMessage(
                        "receive_email_when_order_status_changes",
                        "Receive Email when order status changes",
                      )}
                      disabled={Boolean(!smsStatus && pushStatus)}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </CardContent>
            {customer && (
              <React.Fragment>
                <CardHeader
                  title={getLocalisationMessage("settings")}
                  style={{ paddingBottom: 0 }}
                />
                <CardContent>
                  <CustomButton
                    color={SECONDARY}
                    onClick={() => setOpenDynamicColumns(true)}
                  >
                    {getLocalisationMessage("dynamic_order_list_column")}
                  </CustomButton>
                </CardContent>
              </React.Fragment>
            )}
            <CardActions>
              <Button type="submit">
                {getLocalisationMessage("save_profile", "Save Profile")}
              </Button>
            </CardActions>
          </form>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ProfileForm);

import React, { useEffect, useState } from "react";
import { Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapProps,
  withContext,
  withHandlers,
} from "recompose";
import PropTypes from "prop-types";
import { FieldArray, formValues, reduxForm } from "redux-form";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getValue, toJS } from "../../helpers/DataUtils";
import { createObjectIdValidator, getObjectId } from "../../helpers/FormUtils";
import { formatText, parseOnlyPositiveFloat } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { isEnableEventDateAtBatchUpdate } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COLLECTED,
  CUSTOMER_NOT_PAID,
  PAID_TO_CUSTOMER,
  WITH_WING,
} from "../../constants/AdminCODCodes";
import {
  ACCEPTED,
  ASSIGNED_TO_COURIER,
  COMPLETED,
  DISPATCHED,
  IN_TRANSIT,
  IN_TRANSIT_TO_SUPPLIER,
  isShowWarehouse,
  ON_HIS_WAY,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import transitModeOptions from "../../constants/TransitModeTypes";
import { getUserWarehouse } from "../../reducers/ProfileReducer";
import _ from "lodash";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import {
  AddCircleOutline,
  Close,
  KeyboardArrowLeft,
  Save,
} from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { getCategories } from "../../api/admin/AdminBatchApi";
import TransportationType from "../../constants/TransportationType";

export const INSURED_BAG = "insured_bag";
export const LETTER_BAG = "letter_bag";
export const PERIODIC_NEWS_BAG = "periodic_news_bag";
export const INTERNATIONAL_SHIPMENT_BAG = "international_shipment_bag";
export const INSURED_PACKAGES = "insured_packages";
export const FRAGILE_PARCELS = "fragile_parcels";
export const POST_PACKET = "post_packet";
export const PARCEL = "parcel";
export const COURIER_SERVICE_WITHOUT_BAG = "courier_service_without_bag";
export const ONE_STEP_WITHOUT_BAG = "one_step_without_bag";

export const categoriesConstants = Set.of(
  INSURED_BAG,
  LETTER_BAG,
  POST_PACKET,
  PARCEL,
  COURIER_SERVICE_WITHOUT_BAG,
  ONE_STEP_WITHOUT_BAG,
);

const ContentItems = ({ fields, getLocalisationMessage, userWarehouse }) => {
  const [categories, setCategories] = useState([]);
  useEffect(
    () =>
      getCategories()
        .then(res => setCategories(_.get(res, "data")))
        .catch(error => showErrorMessage(error)),
    [],
  );
  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column">
        {fields.map((item, index) => (
          <FlexBox style={{ margin: ".5rem 0" }} key={index} gutter={8}>
            <FlexBox style={{ flexGrow: 1 }} flex={true}>
              <FormSelectField
                name={`${item}.category`}
                autoWidth={true}
                fullWidth={true}
                options={categories}
                formatOption={x => getValue(x, "name")}
                label={getLocalisationMessage("category")}
                disabled={!categories.length}
                validate={createObjectIdValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true} style={{ flexGrow: ".5" }}>
              <FormTextField
                name={`${item}.qty`}
                label={getLocalisationMessage("quantity")}
                parseOnBlur={parseOnlyPositiveFloat}
              />
            </FlexBox>
            <FlexBox flex={true} style={{ flexGrow: ".5" }}>
              <FormTextField
                name={`${item}.amount`}
                label={getLocalisationMessage("amount_bag")}
                parseOnBlur={parseOnlyPositiveFloat}
              />
            </FlexBox>
            <FlexBox flex={true} style={{ flexGrow: ".5" }}>
              <FormTextField
                name={`${item}.bag_id`}
                label={getLocalisationMessage("bag_number", "Bag Number")}
                parseOnBlur={parseOnlyPositiveFloat}
              />
            </FlexBox>
            <FlexBox flex={true} style={{ flexGrow: ".5" }}>
              <FormTextField
                name={`${item}.weight`}
                label={getLocalisationMessage("weight_kg")}
                parseOnBlur={parseOnlyPositiveFloat}
              />
            </FlexBox>
            <FlexBox style={{ flexGrow: 1 }} flex={true}>
              <FormWarehouseAutoComplete
                validate={createObjectIdValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                name={`${item}.from_warehouse`}
                label={getLocalisationMessage("from_warehouse")}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </FlexBox>
            <FlexBox style={{ flexGrow: "1.5" }} flex={true}>
              <FormWarehouseAutoComplete
                validate={createObjectIdValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                name={`${item}.to_warehouse`}
                label={getLocalisationMessage("destination_warehouse")}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </FlexBox>
            <FlexBox style={{ flexGrow: 1 }} flex={true}>
              <FormTextField
                name={`${item}.note`}
                label={getLocalisationMessage("description")}
                fullWidth={true}
              />
            </FlexBox>
            <FlexBox align={ALIGN_CENTER}>
              <IconButton size="small" onClick={() => fields.remove(index)}>
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox style={{ marginTop: ".5rem" }} align={ALIGN_CENTER}>
        <CustomButton
          variant={OUTLINED}
          color={SECONDARY}
          onClick={() =>
            fields.push({
              qty: 1,
              amount: 0,
              weight: 0,
              from_warehouse: userWarehouse,
            })
          }
          startIcon={<AddCircleOutline />}
        >
          {getLocalisationMessage("add_rules")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
ContentItems.propTypes = {
  fields: PropTypes.object,
  userWarehouse: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

export const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    // case ACCEPTED:
    //   return "Assign Driver For Pickup";

    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

const enhancer = compose(
  connect(state => ({
    userWarehouse: toJS(getUserWarehouse(state)),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isEnableEventDateAtBatchUpdate: isEnableEventDateAtBatchUpdate(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    chip: { margin: "4px" },
    moreInfo: { marginTop: "5px", marginLeft: "10px" },
    form: {
      paddingTop: 15,
      "&> div": {
        marginBottom: 15,
      },
    },
    dialogHeaderContainer: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
    },
    dialogHeaderTitle: {
      flexGrow: 1,
    },
    dialogHeaderToggleContainer: {
      padding: "10px 5px 10px 20px",
      borderRadius: 1000,
      backgroundColor: "#DBE1E6",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSubmit: props => ({
      driver,
      supplier,
      warehouse,
      supplierDriverOnly,
      useOrderNumber,
      orderBarcodes,
      ...restValues
    }) => {
      const values = { ...restValues };

      if (isValidObjectId(driver)) {
        values.driver = driver;
      }

      if (isValidObjectId(warehouse)) {
        values.warehouse = warehouse;
      }

      if (isValidObjectId(supplier)) {
        values.supplier = supplier;
      }

      if (useOrderNumber) {
        values.orderNumbers = orderBarcodes;
      } else {
        values.orderBarcodes = orderBarcodes;
      }

      if (values.codCode) {
        values.paid =
          values.codCode === COLLECTED ||
          values.codCode === WITH_WING ||
          values.codCode === CUSTOMER_NOT_PAID ||
          values.codCode === PAID_TO_CUSTOMER;

        values.paidToWing =
          values.codCode === WITH_WING ||
          values.codCode === CUSTOMER_NOT_PAID ||
          values.codCode === PAID_TO_CUSTOMER;

        values.paidToCustomer = values.codCode === PAID_TO_CUSTOMER;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "BatchUpdateOrderDialog",
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      orderStatus:
        !toJS(props.statusCodes).some(v => v === values.orderStatus) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_status")) ||
          "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),

      transportationType:
        !values.transportationType &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
    }),
  }),
  formValues({
    supplier: "supplier",
    orderStatus: "orderStatus",
    transitMode: "transitMode",
    orderBarcodes: "orderBarcodes",
    supplierDriverOnly: "supplierDriverOnly",
    batchType: "batchType",
    onlyBarcode: "onlyBarcode",
    batch_ids: "batch_ids",
    to_postcode: "to_postcode",
    to_jurisdiction: "to_jurisdiction",
    next_postcode: "next_postcode",
    next_jurisdiction: "next_jurisdiction",
    category: "category",
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,

      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  mapProps(props => ({
    ...props,
    statusCodes: props.statusCodes.delete(OUT_FOR_DELIVERY),
  })),
);

BatchUpdateOrderDialog.propTypes = {
  classes: PropTypes.object,
  to_postcode: PropTypes.object,
  to_jurisdiction: PropTypes.object,
  next_postcode: PropTypes.object,
  next_jurisdiction: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  supplier: PropTypes.object,
  orderStatus: PropTypes.string,
  supplierDriverOnly: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  codCodes: PropTypes.instanceOf(Set),
  privacyTypes: PropTypes.instanceOf(Set),
  statusCodes: PropTypes.instanceOf(Set).isRequired,
  selectedItemPieces: PropTypes.array,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  isContainer: PropTypes.bool,
  title: PropTypes.string,
  userWarehouse: PropTypes.object,
  isOpenUnpaidDialog: PropTypes.bool,
};

BatchUpdateOrderDialog.defaultProps = {
  isSorting: false,
  onlyBarcode: false,
  isSupplierTransitOrdersVisibility: true,
  showSupplierDriverOnly: true,
  transitModeOptions,
};

function BatchUpdateOrderDialog(props) {
  const { classes, getLocalisationMessage, orderStatus, userWarehouse } = props;
  const [isOpenUnpaidDialog, setIsOpenUnpaidDialog] = useState(
    props.isOpenUnpaidDialog,
  );

  const selectedItemPiecesSize = fp.size(props.selectedItemPieces);
  const limit = 8;

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
      onClose={props.onRequestClose}
    >
      <Dialog
        open={isOpenUnpaidDialog}
        maxWidth="auto"
        onClose={props.onRequestClose}
      >
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          <div className={classes.dialogHeaderContainer}>
            <div className={classes.dialogHeaderTitle}>
              {getLocalisationMessage("unpaid_order")}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {getLocalisationMessage("there_a_some_unpaid_orders")}
        </DialogContent>
        <DialogActions>
          <FlexBox
            style={{ margin: "1rem" }}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              startIcon={<KeyboardArrowLeft />}
              primary={true}
              onClick={() => {
                setIsOpenUnpaidDialog(false);
              }}
            >
              {getLocalisationMessage("close_window")}
            </CustomButton>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage(props.title)}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <FormChipInput
            name="orderBarcodes"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
          />

          {selectedItemPiecesSize > 0 &&
            (orderStatus === IN_TRANSIT ||
              orderStatus === IN_TRANSIT_TO_SUPPLIER ||
              orderStatus === DISPATCHED ||
              orderStatus === ACCEPTED) && (
              <div>
                <ListSubheader>
                  {getLocalisationMessage(
                    "please_sure_you_have_scanned_boxes_of_the_shipments",
                    "Please sure you have scanned boxes of the Shipments",
                  )}
                  :
                </ListSubheader>

                <FlexBox wrap={true}>
                  {props.selectedItemPieces.slice(0, limit).map(number => (
                    <Chip key={number} className={classes.chip}>
                      {number}
                    </Chip>
                  ))}
                  {selectedItemPiecesSize - limit > 1 && (
                    <div className={classes.moreInfo}>
                      {getLocalisationMessage("and", "and")}{" "}
                      {getLocalisationMessage("more", "more")}{" "}
                      <strong>{selectedItemPiecesSize - limit}</strong>{" "}
                      {getLocalisationMessage("boxes", "boxes")}
                    </div>
                  )}
                </FlexBox>
              </div>
            )}

          <FormAutoComplete
            disabled={props.isContainer}
            name="orderStatus"
            fullWidth={true}
            label={getLocalisationMessage("status", "Status")}
            options={props.statusCodes}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
            formatOption={x => formatOrderStatus(x, getLocalisationMessage)}
          />

          {props.isContainer && (
            <FormSelectField
              name="transportationType"
              fullWidth={true}
              options={TransportationType}
              formatOption={x =>
                getLocalisationMessage(x.toLowerCase(), formatText(x))
              }
              label={`${getLocalisationMessage(
                "transportation_type",
                "Transportation Type",
              )} *`}
            />
          )}

          {Boolean(
            orderStatus === COMPLETED ||
              orderStatus === OUT_FOR_RETURN ||
              orderStatus === DISPATCHED ||
              orderStatus === ON_HIS_WAY ||
              orderStatus === ACCEPTED ||
              orderStatus === ASSIGNED_TO_COURIER,
          ) && (
            <FormDriverAutoComplete
              name="driver"
              fullWidth={true}
              label={getLocalisationMessage("driver", "Driver")}
              supplierId={
                props.supplierDriverOnly
                  ? getObjectId(props.supplier)
                  : undefined
              }
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          )}

          {isShowWarehouse(orderStatus) && (
            <FormWarehouseAutoComplete
              name="warehouse"
              label={getLocalisationMessage("next_warehouse")}
              postcodeIndexes={
                fp.get("name", props.next_postcode)
                  ? [fp.get("name", props.next_postcode)]
                  : null
              }
              jurisdictionIds={
                !fp.get("name", props.next_postcode) &&
                fp.get("id", props.next_jurisdiction)
                  ? [fp.get("id", props.next_jurisdiction)]
                  : null
              }
              fullWidth={true}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          )}

          <FormTextField
            name="note"
            rows={4}
            rowsMax={40}
            multiLine={true}
            fullWidth={true}
            label={getLocalisationMessage("note_content", "Note Content")}
          />

          {!props.isContainer &&
            (orderStatus === IN_TRANSIT ||
              orderStatus === PREPARED_FOR_TRANSIT) && (
              <FormWarehouseAutoComplete
                disableP7={false}
                name="to_warehouse"
                label={getLocalisationMessage("destination_warehouse")}
                postcodeIndexes={
                  fp.get("name", props.to_postcode)
                    ? [fp.get("name", props.to_postcode)]
                    : null
                }
                jurisdictionIds={
                  !fp.get("name", props.to_postcode) &&
                  fp.get("id", props.to_jurisdiction)
                    ? [fp.get("id", props.to_jurisdiction)]
                    : null
                }
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            )}

          {COMPLETED === orderStatus && (
            <FormSelectField
              name="codCode"
              autoWidth={true}
              fullWidth={true}
              options={props.codCodes}
              label={getLocalisationMessage("cod_status", "Cod Status")}
              formatOption={x => getLocalisationMessage(x) || formatText(x)}
            />
          )}

          {!props.isContainer && (
            <FormTextField
              type="number"
              name="weight"
              fullWidth={true}
              label={getLocalisationMessage("weight_kg", "Weight (kg)")}
            />
          )}
          {props.isContainer && (
            <FieldArray
              name="manual_items"
              props={{
                getLocalisationMessage,
                userWarehouse,
              }}
              component={ContentItems}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <FlexBox
          style={{ marginBottom: "1rem" }}
          gutter={16}
          flex={true}
          justify={JUSTIFY_CENTER}
        >
          <CustomButton
            style={{ marginRight: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            startIcon={<KeyboardArrowLeft />}
            primary={true}
            onClick={props.onRequestClose}
          >
            {getLocalisationMessage("cancel", "Cancel")}
          </CustomButton>
          <CustomButton
            style={{ marginLeft: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            endIcon={<Save />}
            primary={true}
            onClick={props.handleSubmit}
          >
            {getLocalisationMessage("create")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateOrderDialog);

import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { getValue } from "../../helpers/DataUtils";

// colors
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const DEFAULT = "default";
// variants
export const OUTLINED = "outlined";
export const CONTAINED = "contained";
export const TEXT = "";
const enhancer = compose(
  withTheme,
  useSheet({
    root: {
      margin: props => props.theme.spacing(1),
    },
  }),
);

CustomButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  params: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  theme: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
};

function CustomButton(props) {
  const {
    label,
    classes,
    className,
    variant,
    primary,
    secondary,
    children,
    color,
    style,
    disabled,
    size,
    fullWidth,
    ...others
  } = props;
  const foundColor =
    color || (primary ? PRIMARY : secondary ? SECONDARY : DEFAULT);
  const textColor =
    foundColor === SECONDARY && variant === CONTAINED && "white";
  return (
    <Button
      {...others}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      className={cx(className, classes.root)}
      variant={variant}
      color={foundColor}
      style={{
        ...style,
        color: getValue(style, "color", textColor)
          ? getValue(style, "color", textColor)
          : disabled
          ? "black"
          : textColor,
      }}
    >
      {label || children}
    </Button>
  );
}

export default enhancer(CustomButton);
